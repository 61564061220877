/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "../node_modules/ngx-countries-dropdown/assets/styles.css";

body {
  font-family: sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #f3f3f3 !important;
}

.mdc-switch:enabled .mdc-switch__track::after,
.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  background-color: #2f9baa !important;
  border-color: #2f9baa !important;
}

.mat-mdc-paginator {
  background-color: transparent;
  height: 100px;
}

.loading-container {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  gap: 35px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #58A3B1;
}

.ipv_selected-item {
  height: 55px;
  background-color: #f3f3f3;
  border: none;
  border-bottom: 1px solid black;
}

input.ipv_search_box {
  height: 55px;
  left: 0;
}

@media screen and (max-width: 992px) {
  .otp-input {
    width: 40px !important;
    height: 40px !important;
  }
}

@media screen and (max-width: 767px) {
  .otp-input {
    width: 30px !important;
    height: 30px !important;
  }
}

.mat-badge-content {
  transform: translate(-100%, 0%) !important;
  padding: 4px 8px !important;
  background-color: #2f9baa !important;
}

html[dir=rtl] {
  .back-icon {
    transform: rotate(180deg);
  }

  .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-content {
      direction: rtl !important;
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  .mat-mdc-menu-content {
    direction: ltr !important;
  }
}

.pie-label {
  direction: ltr;
}